@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import './styles/variables.scss';

.App {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  // height: 100vh;
}

.container {
  display: flex;
  align-items: center;
  margin: 10px;
  .__button {
    margin-right: 10px;
  }
}

.container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 100px;
}

.logo__label {
  @extend .primary_text;
  font-size: 36px;
  font-weight: 800;
}

h2 {
  @extend .primary_text;
  font-weight: 700;
  font-size: 24px;
}

body {
  background-color: $background;
}
