@import 'styles/variables.scss';

.Draftcraft {
    
}

.loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}