@import 'styles/variables.scss';

.FeatureList {
  background-color: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  padding: 30px 0px 50px 0px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;

    .assertion {
      @extend .primary_text;
      text-align: center;
      margin: 40px 0px 60px 0px;
    }

    .split {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 1437px) {
        justify-content: center;
      }

      .split__left {
        padding: 0px 15px;
        margin: 0px 5px;
        > div {
          margin-bottom: 30px;
        }
      }
      .split__right {
        padding: 0px 15px;
        margin: 0px 5px;
        > div {
          margin-bottom: 30px;
        }
      }
    }
  }

  .illustration {
    padding: 20px;
    margin-bottom: 50px;
    img {
      width: 100%;
      max-width: 550px;
    }
  }

  .icon {
    font-size: 24px;
    color: $primary;
  }

  .button {
    margin: 40px 0px 20px 0px;
  }
}
