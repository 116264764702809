@import 'styles/variables.scss';

.Footer {
  background-color: $dark;
  width: 100%;
  color: $light;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 50px;
    width: 75%;
    > div {
      margin: 10px;
    }

    .link__column {
      display: flex;
      flex-direction: column;
      a {
        color: white;
        &:hover {
          color: $primary;
        }
      }
    }

    @media screen and (max-width: 520px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .center {
    text-align: center;
  }

  .banner__logo {
    height: 80px;
    margin: 8px;
  }
}
