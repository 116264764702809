@import 'styles/variables.scss';

.NotFound {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    height: 130px;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .image {
    height: 350px;
  }

  :global {
    h3.ant-typography,
    .ant-typography h3 {
      color: $empty;
      margin-top: 50px;
    }
  }
}
