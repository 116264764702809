@import 'styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Lexend+Tera&display=swap');

.Cards {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .head {
    background: url('/assets/cards_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 55vh;

    .mask {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .logo {
    width: 50px;
    padding: 8px;
  }

  h1 {
    font-family: 'Lexend Tera', sans-serif;
    font-size: 80px;
    font-weight: 800;
    color: $primary;
    margin-bottom: 30px;
  }
  h2 {
    color: $dark;
    opacity: 1;
    padding: 0px;
    margin: 0px;
  }
  .cta {
    > a > button {
      margin: 30px 15px;
    }
  }

  .desc {
    margin-bottom: 55px;
  }

  .main {
    padding-top: 40px;
  }

  .main__content {
    margin: auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
  }
  .main__content__examples {
    @extend .main__content;
    flex-direction: column;
  }

  .card__title {
    color: $primary;
    align-self: center;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 24px;
  }

  .card__custom {
    margin: 20px;
    width: 370px;
    height: 370px;
    padding: 20px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    text-align: left;
  }
  pre {
    background-color: $light;
    padding: 5px;
    margin: 8px 0px;
    overflow-x: scroll;
    max-width: 100%;
    text-align: left;
  }
}
