@import 'styles/variables.scss';

.Loading {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    height: 250px;
  }

  .label {
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 1px;
    color: $primary;
  }
}
