@import 'styles/variables.scss';

.BasicCardExample {
    padding: 15px;
    text-align: left;

    .title {
        color: $primary;
    }

    pre {
        background-color: $light;
        padding: 5px;
        margin: 8px 0px;
        overflow-x: scroll;
        max-width: 100%;
        text-align: left;
    }

    .tables {
        justify-content: center;
        display: flex;
    }

    table {
        margin: 15px 35px 15px 0px;
        td {
            padding: 5px 20px 5px 0px;
        }
        tr:nth-child(even) {
            background-color: $light;
        }
    }
    .example {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .card__image > div > img {
        height: 275px;
    }
    .card__title {
        background-color: $primary;
    }
    .card__title > h3 {
        padding: 5px;
        color: #fff;
        font-weight: 600;
    }
    .card__content {
        padding: 10px; 
    }
}