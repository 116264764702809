@import 'styles/variables.scss';

.Terms {
  padding: 15px;
  h1 {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }
}
