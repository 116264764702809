@import 'styles/variables.scss';

.FlippableCardExample {
    text-align: left;
    padding: 15px;
    .h2 {
        color: $primary;
    }
    table {
        margin: 15px 35px 15px 0px;
        td {
            padding: 5px 20px 5px 0px;
        }
        tr:nth-child(even) {
            background-color: $light;
        }
    }
    .center {
        display: flex;
        justify-content: center;
    }
    .example {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 15px;
    }
    .card__image > div > img {
        height: 275px;
    }
    .card__image > div {
        overflow: hidden;
    }
    .card__title {
        background-color: $primary;
    }
    .card__title > h3 {
        padding: 5px;
        color: #fff;
        font-weight: 600;
    }
    .card__content {
        padding: 10px; 
    }
    .button {
        margin: 5px;
        display: flex;
        justify-content: flex-end;
    }
}