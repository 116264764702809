@import 'styles/variables.scss';

.Login {
  width: 100%;
  height: 100vh;
  color: $dark;
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: $primary;
  }

  a {
    color: $primary;
    text-decoration: underline;
    :hover,
    :focus {
      color: $primary;
    }
  }

  .logo {
    margin: 24px;
    height: 140px;

    @media screen and (max-width: 400px) {
      height: 100px;
    }
  }

  .container {
    background-color: white;
    text-align: center;
    padding: 24px 36px;
    border-radius: 16px;
    @extend .drop__shadow;

    @media screen and (max-width: 400px) {
      padding: 24px 12px;
    }
  }

  .button {
    width: 320px;
    margin: 10px;
  }

  .space {
    margin-top: 16px;
  }

  .divider {
    @extend .space;
    color: $lightGrey;
    font-size: 36px;
  }

  :global {
    .ant-btn-background-ghost,
    .ant-btn:focus {
      color: $primary !important;
      border-color: $primary !important;
    }

    .ant-btn:hover {
      background-color: $primary !important;
      color: $white !important;
      border-color: $primary !important;
    }

    .ant-btn-link:hover,
    .ant-btn-link:focus,
    .ant-btn-link:active {
      background-color: transparent !important;
      color: $primary !important;
      border: none !important;
    }
  }
}
