@import 'styles/variables.scss';

.DemoVideo {
  background-color: $light;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 25px 10px;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
  @extend .primary_text;

  .container {
    margin-bottom: 25px;
    word-wrap: break-word;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .hero {
    margin-bottom: 10px;
    letter-spacing: 1.2px;
    font-size: 65px;
    font-weight: 800;
    line-height: 57px;
  }

  .hero__red {
    @extend .hero;
    color: $primary;
    margin-right: 5px;
  }
}
