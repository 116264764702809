@import 'styles/variables.scss';

.Subscribed {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  > * {
    padding: 25px;
  }

  .img {
    height: 150px;
  }

  .success__message {
    @extend .primary_text;
    letter-spacing: 1.5px;
  }

  .loading {
    @extend .primary_text;
    margin: 15px;
  }
}
