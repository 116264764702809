@import 'styles/variables.scss';

.Quote {
  width: 100%;
  min-height: 65vh;
  padding: 25px 10px;
  background-color: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  h2 {
    font-size: 30px !important;
    color: $primary !important;
    padding: 20px !important;
  }

  .attribution {
    font-size: 18px;
    color: $medium;
    font-weight: 600;
    letter-spacing: 1.5px;

    > small {
      color: $lightMedium;
    }
  }

  .small {
    font-style: italic;
    color: $medium;
  }

  .img {
    margin: 15px;
    width: 95px;
    height: 95px;
  }
}
