@import 'styles/variables.scss';

.Feedback {
  width: 100%;

  .header {
    display: flex;
    justify-content: center;
  }
  .logo {
    height: 130px;
    margin: 20px auto 50px auto;
    text-align: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    h1 {
      text-align: center;
    }

    .button {
      float: right;
    }
  }

  .success {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #45d07f;
  }

  .check {
    font-size: 36px;
    margin-bottom: 15px;
  }

  .error {
    color: red;
  }

  .container {
    @extend .header;
  }
}
