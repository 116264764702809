@import 'styles/variables.scss';

.ActionDetailsExample {
    @import '../examples.scss';
    padding: 15px;
    text-align: left;
    
    .left {
        > div {
            text-align: left;
        }
    }
}