@import 'styles/variables.scss';

.WordPlugin {
  padding: 80px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
  align-items: center;

  .mainContainer {
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
  }

  .actionContainer {
    display: flex;
  }

  .containerRight {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .partnerText {
    @extend .primary_text;
    letter-spacing: 1.5px;
  }

  .intro {
    @extend .primary_text;
    line-height: 25px;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    padding: 0px 25px;
    min-width: 350px;
  }

  .red {
    color: $primary;
  }

  .msLogo {
    height: 100px;
  }

  .pluginLogo {
    height: 100px;
  }

  .video {
    padding: 20px 0px;
  }

  .install {
    margin-right: 20px;
  }
}
