@import 'styles/variables.scss';

.Welcome {
  height: 100%;

  .hero {
    letter-spacing: 1.2px;
    font-size: 65px;
    font-weight: 800;
    line-height: 57px;
  }

  .hero__red {
    @extend .hero;
    color: $primary;
  }

  .block {
    display: block;
  }

  .container {
    display: flex;
    align-items: center;
    margin: 10px;
    .__button {
      margin-right: 10px;
      @media screen and (max-width: 520px) {
        margin-right: 5px;
        font-size: 12px;
      }
      @media screen and (max-width: 320px) {
        margin-right: 3px;
      }
    }
  }

  .container__header {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .logo {
    height: 80px;
    margin: 8px 24px;

    @media screen and (max-width: 520px) {
      margin: 8px 8px;
      height: 40px;
    }
    @media screen and (max-width: 375px) {
      margin: 8px 16px;
      height: 35px;
    }
  }

  .logo__label {
    @extend .primary_text;
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 0px;

    @media screen and (max-width: 520px) {
      font-size: 24px;
    }
    @media screen and (max-width: 320px) {
      font-size: 18px;
    }
  }

  h2 {
    @extend .primary_text;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 2px;
  }

  .spaced__text {
    @extend .primary_text;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 2px;
  }

  h3 {
    @extend .spaced__text;
  }

  .container__center {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    min-height: 700px;
    flex-direction: column;
    padding-bottom: 10px;

    @media screen and (max-width: 1075px) {
      height: inherit;
      margin: auto;
    }
  }

  .banner {
    width: 75%;
    max-width: 1320px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 5px;
    @media screen and (max-width: 500px) {
      margin-top: 35px;
    }
  }

  .banner__left {
    flex: 1;
  }

  .banner_right {
    flex: 1;
  }

  .button__start {
    margin-top: 25px;
    // font-size: 18px;
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .computer {
    height: 400px;

    @media screen and (max-width: 1075px) {
      height: 400px;
    }
    @media screen and (max-width: 400px) {
      height: 235px;
    }
  }
}
