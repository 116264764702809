$dark: rgb(40, 43, 51);
$darkText: rgba(40, 43, 51, 0.75);
$lightGrey: #c9cacc;
$light: #f1f2fd;
$medium: #4d4d4d;
$lightMedium: #5e5e5e;
$empty: #a1a1a1;
$border-light: #f0f0f0;
$primary: #ff4b4b;
$primaryLight: rgba(255, 75, 75, 0.7);
$white: #fff;
$secondary: #f8f5f0;
$background: #fafafa;
$background-secondary: #f8f8f8;

.primary_text {
  color: $darkText;
}

.drop__shadow {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.zero__state {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.config__button {
  width: 55px;
  height: 55px;
  font-size: 20px;
  color: $primary;
}

.empty__state {
  @extend .zero__state;
  color: $empty;
  flex-direction: column;
  > img {
    margin-bottom: 10px;
  }
}

.inlineEdit {
  .input {
    padding: 15px;
    font-weight: 400;
    letter-spacing: 1px;
    width: 100%;
    border-radius: 20px;
    padding: 7px 15px;
    border: none;
    font-size: 18px;
    font-weight: 500;
  }

  label {
    @extend .primary_text;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .formItem {
    margin-bottom: 20px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;

    .button {
      margin: 4px;
      width: 100px;
    }
  }
}
